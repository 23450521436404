import React from "react";
import HeroSection from "../components/HeroSection";
import StatsSection from "../components/StatsSection";
import MissionHowItWorks from "../components/MissionHowItWorks";
import FeaturesSectionNew from "../components/FeaturesSectionNew";

export const HomePage = () => {
  return (
    <div className="home-page__main">
      <HeroSection />
      <StatsSection />
      <MissionHowItWorks />
      <FeaturesSectionNew />
    </div>
  );
};
