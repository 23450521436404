import React, { useEffect, useState } from "react";
import DisallowedTransactionsFrame from "./DisallowedTransactionsFrame";
import CapitalGainTransactionsFrame from "./CapitalGainTransactionsFrame";
import CapitalLossTransactionsFrame from "./CapitalLossTransactionsFrame";
import TaxLossOpportunitiesFrame from "./TaxLossOpportunitiesFrame";
import SettledTransactionsFrame from "./SettledTransactionsFrame";
import SnapTradeTransactions from "./SnapTradeTransactions";
import WashSaleAvoidance from "./WashSaleAvoidance";
import "../css/Dashboard.css";
import { PrimeReactProvider } from "primereact/api";
import { useNavigate } from "react-router-dom";
import "primereact/resources/themes/saga-green/theme.css";
import axios from "axios";

function DebugDashboard({ username, setModalOpen }) {
  const [hasAccounts, setHasAccounts] = useState(false);
  const [activeTab, setActiveTab] = useState("settledTransactions");
  const navigate = useNavigate();

  useEffect(() => {
    const backendUrl = "https://profitree-backend-9482feeffc4a.herokuapp.com";
    async function getAccountsList() {
      const accountResponse = await axios.post(
        `${backendUrl}/accounts/getList`,
        null,
        {
          params: { username },
        }
      );

      console.log(accountResponse);

      if (
        accountResponse.status === 200 &&
        accountResponse.data &&
        accountResponse.data.length > 0
      ) {
        setHasAccounts(true);
      }
    }
    getAccountsList();
  }, [username]);

  const tooltips = {
    capitalGains: "View your realized capital gains for the current year.",
    capitalLosses: "View your realized capital losses for the current year.",
    taxLossOpportunities: "Discover opportunities to harvest tax losses.",
    disallowedTransactions:
      "See transactions marked as wash sales in the past.",
    washSaleAvoidance:
      "See which tickers/stocks you should avoid trading to prevent triggering wash sales.",
  };

  const handleAddFirstAccount = () => {
    setModalOpen({
      open: true,
      type: "account",
    });
  };

  return hasAccounts ? (
    <PrimeReactProvider>
      <div className="dashboard-container">
        <div className="content-wrapper">
          <div className="primary-cta-wrapper-dashboard">
            <button className="cta-button" onClick={handleAddFirstAccount}>
              Add Account
            </button>
          </div>
          <div className="tabs">
            <div className="tooltip-container">
              <button
                onClick={() => setActiveTab("settledTransactions")}
                className={activeTab === "settledTransactions" ? "active" : ""}
              >
                Settled Transactions
              </button>
              <span className="tooltip">{tooltips.capitalGains}</span>
            </div>
            <div className="tooltip-container">
              <button
                onClick={() => setActiveTab("snaptradeTransactions")}
                className={activeTab === "snaptradeTransactions" ? "active" : ""}
              >
                Snaptrade Transactions
              </button>
              <span className="tooltip">{tooltips.capitalLosses}</span>
          </div>
          </div>
          <div className="tab-content">
            {activeTab === "settledTransactions" && (
              <SettledTransactionsFrame username={username} />
            )}
            {activeTab === "snaptradeTransactions" && (
              <SnapTradeTransactions username={username} />
            )}
          </div>
        </div>
      </div>
    </PrimeReactProvider>
  ) : (
    <div className="dashboard-container dashboard-container--no-accounts">
      <div className="content-wrapper">
        <p>You currently do not have any accounts added.</p>
        <p>Start by adding accounts to your dashboard!</p>
        <div className="primary-cta-wrapper-dashboard">
          <div className="tooltip-container">
            <button className="cta-button" onClick={handleAddFirstAccount}>
              Add Account
            </button>
            <span className="tooltip">
              <strong>Your Data is Safe:</strong> ProfiTree connects securely
              via SnapTrade using advanced encryption and read-only access.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DebugDashboard;
