import React from 'react';
import BlogCard from './BlogCard';
import '../css/BlogGrid.css';
import tax_loss_haresting_image from '../assets/tax_loss_haresting.jpg';
import wash_sale_image from '../assets/wash_sale.png';
import cost_basis_image from '../assets/cost_basis.png';

const BlogGrid = () => {
    const blogs = [
        {
            image: tax_loss_haresting_image,
            title: "What Is Tax-Loss Harvesting and Why It Matters for DIY Investors?",
            date: 'January 16th, 2025',
            description: 'A comprehensive guide to understanding tax-loss harvesting and its benefits for DIY investors.',
            url: '/blog/tax-loss-harvesting',
        },
        {
            image: wash_sale_image,
            title: 'The Hidden Cost of Wash Sales: How to Avoid Common Pitfalls',
            date: 'January 9th, 2025',
            description: 'An in-depth look at wash sales and how to avoid their pitfalls.',
            url: '/blog/wash-sales',
        },
        {
            image: cost_basis_image,
            title: '5 Cost Basis Strategies to Optimize Gains and Losses',
            date: 'January 2nd, 2025',
            description: 'Strategies to optimize your cost basis for better tax outcomes.',
            url: '/blog/cost-basis-strategies',
        },
    ];

    return (
        <div className="blog-grid">
            {blogs.map((blog, index) => (
                <BlogCard
                    key={index}
                    image={blog.image}
                    title={blog.title}
                    date={blog.date}
                    description={blog.description}
                    url={blog.url}
                />
            ))}
        </div>
    );
};

export default BlogGrid;
