import React from "react";
import "../css/PrivacyPolicies.css";

const PrivacyPolicies = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy for ProfiTree</h1>

      <p><strong>Effective Date:</strong> 11th November 2024</p>

      <p>
        At ProfiTree, your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our services.
      </p>

      <hr />

      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of information:</p>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, phone number, and other account details you provide during registration.</li>
        <li><strong>Financial Information:</strong> Data related to your investment accounts if you connect them to ProfiTree.</li>
        <li><strong>Usage Data:</strong> Information about how you use our website or application, such as IP address, browser type, and pages viewed.</li>
      </ul>

      <hr />

      <h2>2. How We Use Your Information</h2>
      <p>Your information is used to:</p>
      <ul>
        <li>Provide and improve our services.</li>
        <li>Personalize your user experience.</li>
        <li>Communicate with you about updates, promotions, and support.</li>
        <li>Ensure compliance with legal and regulatory requirements.</li>
      </ul>

      <hr />

      <h2>3. Sharing Your Information</h2>
      <p>
        ProfiTree does not sell or share your personal information with third parties except in the following circumstances:
      </p>
      <ul>
        <li>With your explicit consent.</li>
        <li>To trusted service providers who help us operate our platform (e.g., payment processors or cloud service providers).</li>
        <li>As required by law or to protect the rights and safety of ProfiTree or its users.</li>
      </ul>

      <hr />

      <h2>4. Data Security</h2>
      <p>
        We implement industry-standard measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
      </p>
      <ul>
        <li>Encryption for sensitive data.</li>
        <li>Secure servers and firewalls.</li>
        <li>Regular audits and security updates.</li>
      </ul>
      <p>However, no system is entirely secure, and we cannot guarantee absolute security.</p>

      <hr />

      <h2>5. Your Rights</h2>
      <p>You have the following rights regarding your personal data:</p>
      <ul>
        <li>Access your data and request a copy of it.</li>
        <li>Request corrections to inaccurate or incomplete data.</li>
        <li>Request the deletion of your data (subject to legal obligations).</li>
        <li>Opt-out of marketing communications.</li>
      </ul>

      <hr />

      <h2>6. Cookies</h2>
      <p>
        ProfiTree uses cookies to enhance your experience. Cookies are small text files stored on your device to:
      </p>
      <ul>
        <li>Remember your preferences and settings.</li>
        <li>Analyze site usage and improve functionality.</li>
      </ul>
      <p>
        You can manage or disable cookies through your browser settings, but some features of our website may not work properly without them.
      </p>

      <hr />

      <h2>7. Third-Party Services</h2>
      <p>
        ProfiTree may integrate with third-party services. These services have their own privacy policies, and we are not responsible for their practices. Please review their policies before using them.
      </p>

      <hr />

      <h2>8. Changes to This Privacy Policy</h2>
      <p>
        ProfiTree reserves the right to update this Privacy Policy at any time. Changes will be effective upon posting on our website. We encourage you to review this policy periodically.
      </p>

      <hr />

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </p>
      <address>
        <strong>ProfiTree Support</strong>
        <br />
        Email: privacy@profitree-tax.com
      </address>

      <p>
        By using ProfiTree, you consent to the terms of this Privacy Policy.
      </p>
    </div>
  );
};

export default PrivacyPolicies;