import React from "react";
import "../css/ComparisonTable.css"; // Import the CSS file

const comparisonData = [
  {
    feature: "Cost & Fees",
    traditional: "❌ High hourly rates",
    robo: "⚠️ Lower, but takes % of AUM",
    profitree: "✅ Flat fee + performance-based pricing",
  },
  {
    feature: "Tax-Loss Harvesting",
    traditional: "❌ Manual, once a year",
    robo: "⚠️ Automated, but limited",
    profitree: "✅ Proactive, real-time loss harvesting",
  },
  {
    feature: "Wash Sale Protection",
    traditional: "❌ Rarely monitored",
    robo: "⚠️ Basic tracking",
    profitree: "✅ Smart alerts to protect tax benefits",
  },
  {
    feature: "Customization & Control",
    traditional: "❌ Advisor makes decisions",
    robo: "❌ Limited flexibility",
    profitree: "✅ Full control, guided insights",
  },
  {
    feature: "Availability",
    traditional: "❌ Requires scheduling",
    robo: "✅ 24/7 but rigid",
    profitree: "✅ 24/7 real-time alerts, full flexibility",
  },
  {
    feature: "Actionable Insights",
    traditional: "❌ You wait for reports",
    robo: "⚠️ Limited tax insights",
    profitree: "✅ Instant tax optimization & savings tracking",
  },
  {
    feature: "Hassle-Free Management",
    traditional: "❌ Meetings & paperwork",
    robo: "✅ Hands-off investing",
    profitree: "✅ Simple, transparent, user-driven",
  },
];

const ComparisonTable = () => {
  return (
    <div className="comparison-container">
      <h2 className="comparison-title">🚀 Take Back Control of Your Money</h2>
      <p className="comparison-subtitle">Why let others decide? ProfiTree gives you <strong>more savings, more control, and real-time insights.</strong></p>

      <table className="comparison-table">
        <thead>
          <tr>
            <th>Feature</th>
            <th>👨‍💼 Traditional Advisor</th>
            <th>🤖 Robo-Advisor</th>
            <th className="highlight">🌳 ProfiTree</th>
          </tr>
        </thead>
        <tbody>
          {comparisonData.map((row, index) => (
            <tr key={index}>
              <td className="feature-cell">{row.feature}</td>
              <td>{row.traditional}</td>
              <td>{row.robo}</td>
              <td className="highlight">{row.profitree}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="cta-container">
        <button className="cta-button">Try ProfiTree Now 🚀</button>
      </div>
    </div>
  );
};

export default ComparisonTable;