import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from "recharts";
import "../css/TaxSavingsCalculator.css"; // Import the CSS

const TaxSavingsCalculator = () => {
  const [years, setYears] = useState(30);
  const [portfolioValue, setPortfolioValue] = useState(100000);
  const [annualContribution, setAnnualContribution] = useState(10000);
  const [returnRate, setReturnRate] = useState(7);
  const [feeRate, setFeeRate] = useState(1);
  const [data, setData] = useState([]);

  useEffect(() => {
    calculateGrowth();
  }, [years, portfolioValue, annualContribution, returnRate, feeRate]);

  const calculateGrowth = () => {
    let dataPoints = [];
    let valueWithoutFees = portfolioValue;
    let valueWithFees = portfolioValue;
    const returnDecimal = returnRate / 100;
    const feeDecimal = feeRate / 100;

    for (let i = 0; i <= years; i++) {
      valueWithoutFees = valueWithoutFees * (1 + returnDecimal) + annualContribution;
      valueWithFees = valueWithFees * (1 + returnDecimal - feeDecimal) + annualContribution;

      dataPoints.push({
        year: new Date().getFullYear() + i,
        withoutFees: Math.round(valueWithoutFees),
        withFees: Math.round(valueWithFees),
      });
    }

    setData(dataPoints);
  };

  return (
    <div className="calculator-grid">
      {/* Input Form */}
      <div className="input-container">
        <h3>Maximize Wealth, Minimize Taxes</h3>
        <label>How many years until you want to retire?</label>
        <input type="number" value={years} onChange={(e) => setYears(+e.target.value)} />

        <label>What is your portfolio's current value?</label>
        <input type="number" value={portfolioValue} onChange={(e) => setPortfolioValue(+e.target.value)} />

        <label>What is your projected annual contribution?</label>
        <input type="number" value={annualContribution} onChange={(e) => setAnnualContribution(+e.target.value)} />

        <div className="inline-inputs">
          <div>
            <label>Estimated Annual Return (%)</label>
            <input type="number" value={returnRate} onChange={(e) => setReturnRate(+e.target.value)} />
          </div>
          <div>
            <label>Asset Manager Fee (%)</label>
            <input type="number" value={feeRate} onChange={(e) => setFeeRate(+e.target.value)} />
          </div>
        </div>
      </div>

      {/* Chart */}
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={350}>
          <LineChart data={data}>
              <XAxis dataKey="year" />
              <YAxis
                  tickFormatter={(value) => `$${(value / 1_000_000).toFixed(1)}M`}
              />
              <Tooltip
                  formatter={(value) => `$${value.toLocaleString()}`}
              />
              <Legend />
              <Line type="monotone" dataKey="withoutFees" stroke="#6b5bfc" strokeWidth={3} name="Portfolio without Fees" />
              <Line type="monotone" dataKey="withFees" stroke="#ff5c5c" strokeWidth={3} name="Portfolio with Fees" />
          </LineChart>

        </ResponsiveContainer>
        <div className="summary">
          <p>
            <strong>Portfolio value without fees:</strong> <span>${data[data.length - 1]?.withoutFees.toLocaleString()}</span>
          </p>
          <p>
            <strong>Portfolio value with fees:</strong> <span>${data[data.length - 1]?.withFees.toLocaleString()}</span>
          </p>
          <p className="loss">
            <strong>Wealth lost in AUM fees:</strong> <span>${(data[data.length - 1]?.withoutFees - data[data.length - 1]?.withFees).toLocaleString()}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TaxSavingsCalculator;
