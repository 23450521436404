import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import "../css/Profile.css";
import TaxRateData from "../tax_assets/us_tax_data.json";
import { ToastContainer, toast, Bounce } from "react-toastify";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";

const backendUrl = "https://profitree-backend-9482feeffc4a.herokuapp.com"; // Replace with your actual backend URL

const Profile = ({ username, setModalOpen }) => {
  const [currentProfile, setCurrentProfile] = useState();
  const [profile, setProfile] = useState({
    email: username,
    country: "US",
    filingStatus: "",
    state: "",
    incomeBracketFederalShortTermTaxRate: "",
    incomeBracketFederalLongTermTaxRate: "",
    federalLongTermTaxRate: 0,
    federalShortTermTaxRate: 0,
    incomeBracketStateTaxRate: "",
    stateTaxRate: 0,
    totalLongTermTaxRate: 0,
    totalShortTermTaxRate: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [stats, setStats] = useState({
    activeAccounts: 0,
    activeInvestments: 0,
    taxSavings: 0,
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${backendUrl}/profile/${username}`);
        if (response.data.federalLongTermTaxRate) {
          response.data.federalLongTermTaxRate =
            parseFloat(response.data.federalLongTermTaxRate) / 100;
        }
        if (response.data.federalShortTermTaxRate) {
          response.data.federalShortTermTaxRate =
            parseFloat(response.data.federalShortTermTaxRate) / 100;
        }
        if (response.data.stateTaxRate) {
          response.data.stateTaxRate =
            parseFloat(response.data.stateTaxRate) / 100;
        }
        setCurrentProfile({
          ...profile,
          ...response.data,
        });
        setProfile({
          ...profile,
          ...response.data,
        });
        

        // Fetch additional stats (assuming an endpoint exists)
        //const statsResponse = await axios.get(`${backendUrl}/profile/${username}/stats`);
        //setStats(statsResponse.data);
      } catch (err) {
        setError("Failed to fetch profile.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [username]);

  const renderFederalShortTermTaxBrackets = useMemo(() => {
    return TaxRateData.federal.short_term?.[profile.filingStatus]?.map(
      (range) => {
        return (
          <option
            selected={
              profile.incomeBracketFederalShortTermTaxRate ==
              `${range.min}-${range.max}`
            }
            key={`federal_short_term_${profile.filingStatus}_${range.max}`}
            value={`${range.min}-${range.max}|${range.rate}`}
          >
            {range.max === "Infinity"
              ? `Greater than $${range.min}`
              : `$${range.min} - $${range.max}`}
            &nbsp;({(range.rate * 100).toFixed(2)}%)
          </option>
        );
      }
    );
  }, [profile.filingStatus]);

  const renderFederalLongTermTaxBrackets = useMemo(() => {
    return TaxRateData.federal.long_term?.[profile.filingStatus]?.map(
      (range) => (
        <option
          selected={
            profile.incomeBracketFederalLongTermTaxRate ==
            `${range.min}-${range.max}`
          }
          key={`federal_long_term_${profile.filingStatus}_${range.max}`}
          value={`${range.min}-${range.max}|${range.rate}`}
        >
          {range.max === "Infinity"
            ? `Greater than $${range.min}`
            : `$${range.min} - $${range.max}`}
          &nbsp;({(range.rate * 100).toFixed(2)}%)
        </option>
      )
    );
  }, [profile.filingStatus]);

  const renderStateTaxBrackets = useMemo(() => {
    return (
      TaxRateData.states[profile.state]?.[profile.filingStatus]?.map(
        (range) => (
          <option
            selected={profile.stateTaxRate == `${range.min}-${range.max}`}
            key={`${profile.state}_${profile.filingStatus}_${range.min}-${range.max}`} // Unique key
            value={`${range.min}-${range.max}|${range.rate}`} // Store both income range and rate
          >
            {range.max === "Infinity"
              ? `Greater than $${range.min}`
              : `$${range.min} - $${range.max}`}
            &nbsp;({(range.rate * 100).toFixed(2)}%)
          </option>
        )
      ) || []
    ); // Return empty array if no data exists to prevent errors
  }, [profile.filingStatus, profile.state]);

  if (loading) return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
      }}
    >
      <Lottie
        animationData={loadingAnimation}
        style={{ height: "150px", width: "150px" }} // Adjust loader size
      />
    </div>
  );;
  if (error) return <div className="dashboard-container dashboard-container--no-accounts">
  <div className="content-wrapper">
    <p>We could not find a profile for this user.</p>
    <p>Click the button below to create your profile.</p>
    <div className="primary-cta-wrapper-dashboard">
      <div className="tooltip-container">
        <button className="cta-button" onClick={() => setModalOpen({ open: true, type: "profile" })}>
          Create Profile
        </button>
      </div>
    </div>
  </div>
</div>
  if (!profile) return <p>No profile found.</p>;

  const validateProfile = () => {
    if(!profile.email.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      return false;
    }
    if(profile.filingStatus === "") {
      return false;
    }
    if(profile.incomeBracketFederalLongTermTaxRate === "") {
      return false;
    }
    if(profile.incomeBracketFederalShortTermTaxRate === "") {
      return false;
    }
    if(profile.incomeBracketStateTaxRate === "") {
      return false;
    }
    if(profile.state === "") {
      return false;
    }
    return true;
  }

  const submitTaxRateInfo = async () => {
    try {
      const response = await axios.put(`${backendUrl}/profile/${username}`, {
        username,
        email: profile.email,
        filingStatus: profile.filingStatus,
        state: profile.state,

        incomeBracketFederalShortTermTaxRate:
          profile.incomeBracketFederalShortTermTaxRate,
        federalShortTermTaxRate: Number(profile.federalShortTermTaxRate) * 100,

        incomeBracketFederalLongTermTaxRate:
          profile.incomeBracketFederalLongTermTaxRate,
        federalLongTermTaxRate: Number(profile.federalLongTermTaxRate) * 100,

        incomeBracketStateTaxRate: profile.incomeBracketStateTaxRate,
        stateTaxRate: Number(profile.stateTaxRate) * 100,

        totalShortTermTaxRate:
          (Number(profile.federalShortTermTaxRate) +
            Number(profile.stateTaxRate)) *
          100,

        totalLongTermTaxRate:
          (Number(profile.federalLongTermTaxRate) +
            Number(profile.stateTaxRate)) *
          100,
      });

      toast.success("Profile updated successfully", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      console.log(response);
    } catch (err) {
      console.error("Error submitting tax rate info:", err);
    }
  };

  return (
    <div className="container margin-top-20">
      <div className="row gutters-sm">
        <div className="col-md-4 d-none d-md-block">
          <div className="card">
            <div className="card-body">
              <nav className="nav flex-column nav-pills nav-gap-y-1">
                <a
                  href="#profile"
                  data-toggle="tab"
                  className="nav-item nav-link has-icon nav-link-faded active"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-user mr-2"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  Profile Information
                </a>
                <a
                  href="#account"
                  data-toggle="tab"
                  className="nav-item nav-link has-icon nav-link-faded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-settings mr-2"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                  </svg>
                  Account Settings
                </a>
                <a
                  href="#security"
                  data-toggle="tab"
                  className="nav-item nav-link has-icon nav-link-faded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-shield mr-2"
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                  </svg>
                  Security
                </a>
                <a
                  href="#notification"
                  data-toggle="tab"
                  className="nav-item nav-link has-icon nav-link-faded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-bell mr-2"
                  >
                    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                  </svg>
                  Notification
                </a>
                <a
                  href="#billing"
                  data-toggle="tab"
                  className="nav-item nav-link has-icon nav-link-faded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-credit-card mr-2"
                  >
                    <rect
                      x="1"
                      y="4"
                      width="22"
                      height="16"
                      rx="2"
                      ry="2"
                    ></rect>
                    <line x1="1" y1="10" x2="23" y2="10"></line>
                  </svg>
                  Billing
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card">
            <div className="card-header border-bottom mb-3 d-flex d-md-none">
              <ul
                className="nav nav-tabs card-header-tabs nav-gap-x-1"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    href="#profile"
                    data-toggle="tab"
                    className="nav-link has-icon active"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#account"
                    data-toggle="tab"
                    className="nav-link has-icon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-settings"
                    >
                      <circle cx="12" cy="12" r="3"></circle>
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                    </svg>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#security"
                    data-toggle="tab"
                    className="nav-link has-icon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-shield"
                    >
                      <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                    </svg>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#notification"
                    data-toggle="tab"
                    className="nav-link has-icon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-bell"
                    >
                      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                      <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#billing"
                    data-toggle="tab"
                    className="nav-link has-icon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-credit-card"
                    >
                      <rect
                        x="1"
                        y="4"
                        width="22"
                        height="16"
                        rx="2"
                        ry="2"
                      ></rect>
                      <line x1="1" y1="10" x2="23" y2="10"></line>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body tab-content">
              <div className="tab-pane active" id="profile">
                <h6>YOUR PROFILE INFORMATION</h6>
                <hr />
                <form>
                  <div className="form-group">
                    <label for="fullName">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="fullName"
                      aria-describedby="EmailID"
                      placeholder="Enter your fullname"
                      value={profile.email}
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          email: e.target.value,
                        });
                      }}
                    />
                    <small id="fullNameHelp" className="form-text text-muted">
                      Your name may appear around here where you are mentioned.
                      You can change or remove it at any time.
                    </small>
                  </div>
                  <div className="form-group nice-form-group">
                    <label for="bio">Country</label>
                    <select
                      value={profile.country}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          country: e.target.value,
                        })
                      }
                    >
                      <option value="US">US</option>
                    </select>
                  </div>

                  <div class="form-group nice-form-group">
                    <label>Filing Status</label>
                    <select
                      value={profile.filingStatus}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          incomeBracketFederalShortTermTaxRate: "",
                          incomeBracketFederalLongTermTaxRate: "",
                          incomeBracketStateTaxRate: "",
                          federalLongTermTaxRate: 0,
                          federalShortTermTaxRate: 0,
                          stateTaxRate: 0,
                          filingStatus: e.target.value,
                        })
                      }
                    >
                      <option disabled value="">
                        {" "}
                        -- select an option --{" "}
                      </option>
                      <option value="single">Single</option>
                      <option value="married_filing_jointly">
                        Married jointly
                      </option>
                      <option value="married_filing_separately">
                        Married separately
                      </option>
                      <option value="head_of_household">
                        Head of household
                      </option>
                    </select>
                  </div>

                  <div className="form-group nice-form-group">
                    <label>
                      Income Bracket for Short Term Federal Tax Rate
                    </label>
                    <select
                      onChange={(e) => {
                        const [incomeBracket, taxRate] =
                          e.target.value.split("|");
                        setProfile({
                          ...profile,
                          incomeBracketFederalShortTermTaxRate: incomeBracket,
                          federalShortTermTaxRate: parseFloat(taxRate),
                        });
                      }}
                    >
                      <option value=""> -- select an option -- </option>
                      {renderFederalShortTermTaxBrackets}
                    </select>
                  </div>

                  <div className="form-group nice-form-group">
                    <label>Income Bracket for Long Term Federal Tax Rate</label>
                    <select
                      onChange={(e) => {
                        const [incomeBracket, taxRate] =
                          e.target.value.split("|");
                        setProfile({
                          ...profile,
                          incomeBracketFederalLongTermTaxRate: incomeBracket,
                          federalLongTermTaxRate: parseFloat(taxRate),
                        });
                      }}
                    >
                      <option value=""> -- select an option -- </option>
                      {renderFederalLongTermTaxBrackets}
                    </select>
                  </div>

                  <div className="form-group nice-form-group">
                    <p>
                      Your calculated long term federal tax rate:{" "}
                      {profile.federalLongTermTaxRate * 100}%
                    </p>
                  </div>

                  <div className="form-group nice-form-group">
                    <p>
                      Your calculated short term federal tax rate:{" "}
                      {profile.federalShortTermTaxRate * 100}%
                    </p>
                  </div>

                  <div className="form-group nice-form-group">
                    <label>State</label>
                    <select
                      value={profile.state}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          stateTaxRate: 0,
                          incomeBracketStateTaxRate: "",
                          state: e.target.value,
                        })
                      }
                    >
                      <option disabled value="">
                        {" "}
                        -- select an option --{" "}
                      </option>
                      {Object.keys(TaxRateData.states)
                        .sort()
                        .map((state) => {
                          return (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="form-group nice-form-group">
                    <label>Income Bracket for State Tax Rate</label>
                    <select
                      value={
                        profile.incomeBracketStateTaxRate
                          ? `${profile.incomeBracketStateTaxRate}|${profile.stateTaxRate}`
                          : ""
                      } // Set to empty string for default option
                      onChange={(e) => {
                        const [incomeBracket, taxRate] =
                          e.target.value.split("|");
                        setProfile({
                          ...profile,
                          incomeBracketStateTaxRate: incomeBracket,
                          stateTaxRate: parseFloat(taxRate),
                        });
                      }}
                    >
                      <option value=""> -- select an option -- </option>{" "}
                      {/* Ensure this option exists */}
                      {renderStateTaxBrackets}
                    </select>
                  </div>

                  <div className="form-group nice-form-group">
                    <p>
                      Your calculated state tax rate:{" "}
                      {profile.stateTaxRate * 100}%
                    </p>
                  </div>

                  <div className="form-group nice-form-group">
                    <p>
                      Your calculated total effective long term tax rate:{" "}
                      {profile.federalLongTermTaxRate * 100 +
                        profile.stateTaxRate * 100}
                      %
                    </p>
                  </div>
                  <div className="form-group nice-form-group">
                    <p>
                      Your calculated total effective short term tax rate:{" "}
                      {profile.federalShortTermTaxRate * 100 +
                        profile.stateTaxRate * 100}
                      %
                    </p>
                  </div>

                  {/* <div className="form-group">
                <label for="location">Location</label>
                <input type="text" className="form-control" id="location" placeholder="Enter your location" value="Bay Area, San Francisco, CA" />
              </div> */}
                  {/* <div className="form-group small text-muted">
                All of the fields on this page are optional and can be deleted at any time, and by filling them out, you're giving us consent to share this data wherever your user profile appears.
              </div> */}
                  <button
                    type="button"
                    onClick={submitTaxRateInfo}
                    className="btn btn-primary"
                    disabled={!validateProfile()}
                  >
                    Update Profile
                  </button>
                  <button type="button" onClick={() => {
                    setProfile({...currentProfile});
                  }} className="btn btn-light">
                    Reset Changes
                  </button>
                </form>
              </div>
              <div className="tab-pane" id="account">
                <h6>ACCOUNT SETTINGS</h6>
                <hr />
                <form>
                  <div className="form-group">
                    <label for="username">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      aria-describedby="usernameHelp"
                      placeholder="Enter your username"
                      value="kennethvaldez"
                    />
                    <small id="usernameHelp" className="form-text text-muted">
                      After changing your username, your old username becomes
                      available for anyone else to claim.
                    </small>
                  </div>
                  <hr />
                  <div className="form-group">
                    <label className="d-block text-danger">
                      Delete Account
                    </label>
                    <p className="text-muted font-size-sm">
                      Once you delete your account, there is no going back.
                      Please be certain.
                    </p>
                  </div>
                  <button className="btn btn-danger" type="button">
                    Delete Account
                  </button>
                </form>
              </div>
              <div className="tab-pane" id="security">
                <h6>SECURITY SETTINGS</h6>
                <hr />
                <form>
                  <div className="form-group">
                    <label className="d-block">Change Password</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your old password"
                    />
                    <input
                      type="text"
                      className="form-control mt-1"
                      placeholder="New password"
                    />
                    <input
                      type="text"
                      className="form-control mt-1"
                      placeholder="Confirm new password"
                    />
                  </div>
                </form>
                <hr />
                <form>
                  <div className="form-group">
                    <label className="d-block">Two Factor Authentication</label>
                    <button className="btn btn-info" type="button">
                      Enable two-factor authentication
                    </button>
                    <p className="small text-muted mt-2">
                      Two-factor authentication adds an additional layer of
                      security to your account by requiring more than just a
                      password to log in.
                    </p>
                  </div>
                </form>
                <hr />
                <form>
                  <div className="form-group mb-0">
                    <label className="d-block">Sessions</label>
                    <p className="font-size-sm text-secondary">
                      This is a list of devices that have logged into your
                      account. Revoke any sessions that you do not recognize.
                    </p>
                    <ul className="list-group list-group-sm">
                      <li className="list-group-item has-icon">
                        <div>
                          <h6 className="mb-0">
                            San Francisco City 190.24.335.55
                          </h6>
                          <small className="text-muted">
                            Your current session seen in United States
                          </small>
                        </div>
                        <button
                          className="btn btn-light btn-sm ml-auto"
                          type="button"
                        >
                          More info
                        </button>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
              <div className="tab-pane" id="notification">
                <h6>NOTIFICATION SETTINGS</h6>
                <hr />
                <form>
                  <div className="form-group">
                    <label className="d-block mb-0">Security Alerts</label>
                    <div className="small text-muted mb-3">
                      Receive security alert notifications via email
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        checked=""
                      />
                      <label
                        className="custom-control-label"
                        for="customCheck1"
                      >
                        Email each time a vulnerability is found
                      </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck2"
                        checked=""
                      />
                      <label
                        className="custom-control-label"
                        for="customCheck2"
                      >
                        Email a digest summary of vulnerability
                      </label>
                    </div>
                  </div>
                  <div className="form-group mb-0">
                    <label className="d-block">SMS Notifications</label>
                    <ul className="list-group list-group-sm">
                      <li className="list-group-item has-icon">
                        Comments
                        <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            checked=""
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitch1"
                          ></label>
                        </div>
                      </li>
                      <li className="list-group-item has-icon">
                        Updates From People
                        <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch2"
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitch2"
                          ></label>
                        </div>
                      </li>
                      <li className="list-group-item has-icon">
                        Reminders
                        <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            checked=""
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitch3"
                          ></label>
                        </div>
                      </li>
                      <li className="list-group-item has-icon">
                        Events
                        <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch4"
                            checked=""
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitch4"
                          ></label>
                        </div>
                      </li>
                      <li className="list-group-item has-icon">
                        Pages You Follow
                        <div className="custom-control custom-control-nolabel custom-switch ml-auto">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch5"
                          />
                          <label
                            className="custom-control-label"
                            for="customSwitch5"
                          ></label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </form>
              </div>
              <div className="tab-pane" id="billing">
                <h6>BILLING SETTINGS</h6>
                <hr />
                <form>
                  <div className="form-group">
                    <label className="d-block mb-0">Payment Method</label>
                    <div className="small text-muted mb-3">
                      You have not added a payment method
                    </div>
                    <button className="btn btn-info" type="button">
                      Add Payment Method
                    </button>
                  </div>
                  <div className="form-group mb-0">
                    <label className="d-block">Payment History</label>
                    <div className="border border-gray-500 bg-gray-200 p-3 text-center font-size-sm">
                      You have not made any payment.
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
};

export default Profile;
