import React, { useState, useEffect } from "react";
import "../css/HeroSection.css"; // Your existing styles
import image1 from "../assets/01.png"; // Replace with your image paths
import image2 from "../assets/02.png";
import image3 from "../assets/03.png";
import image4 from "../assets/04.png";
import image5 from "../assets/05.png";
import Lottie from "lottie-react";
import greenCheck from "../assets/greencheck.json";

const HeroSection = () => {
  const images = [image1, image2, image3, image4, image5]; // Array of images
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Current image index

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <section className="hero-section">
      <div className="content-wrapper">
        {/* Left Side: Text */}
        <div className="hero-text">
          <h1>Turn Tax Drains Into Financial Gains</h1>
          {/* <h2>Meet Automated Tax-Loss Harvesting.</h2> */}
          <p>
            ProfiTree empowers you to save more and invest smarter by
            simplifying tax-loss harvesting. It provides actionable insights to
            help you maximize your after-tax returns and achieve your financial
            goals.
          </p>
          <ul>
            <li>
              <Lottie
                animationData={greenCheck}
                style={{
                  height: "70px",
                  width: "70px",
                  position: "relative",
                  left: "-25px",
                  marginRight: "-30px",
                }}
              />
              <span>Track capital gains & losses</span>
            </li>
            <li>
              <Lottie
                animationData={greenCheck}
                style={{
                  height: "70px",
                  width: "70px",
                  position: "relative",
                  left: "-25px",
                  marginRight: "-30px",
                }}
              />
              <span>Leverage Tax-optimized strategies</span>
            </li>
            <li>
              <Lottie
                animationData={greenCheck}
                style={{
                  height: "70px",
                  width: "70px",
                  position: "relative",
                  left: "-25px",
                  marginRight: "-30px",
                }}
              />
              <span>Avoid Wash Sales</span>
            </li>
            <li>
              <Lottie
                animationData={greenCheck}
                style={{
                  height: "70px",
                  width: "70px",
                  position: "relative",
                  left: "-25px",
                  marginRight: "-30px",
                }}
              />
              <span>Optimize Cost Basis</span>
            </li>
            <li>
              <Lottie
                animationData={greenCheck}
                style={{
                  height: "70px",
                  width: "70px",
                  position: "relative",
                  left: "-25px",
                  marginRight: "-30px",
                }}
              />
              <span>Boost After-Tax Returns</span>
            </li>
          </ul>
          {/* Add CTA Button */}
          <div className="hero__cta-container">
            <button
              className="cta-button"
              onClick={() => {
                window.location.href = "/signup"; // Replace with your sign-up URL
              }}
            >
              Get Started for Free
            </button>

            <button
              className="cta-secondary-button"
              onClick={() => {
                window.location.href = "/learning"; // Replace with your learn TLH URL
              }}
            >
              Learn More on TLH
            </button>
          </div>
        </div>

        {/* Right Side: Image Slideshow */}
        <div className="hero-slideshow">
          <img
            src={images[currentImageIndex]}
            alt="ProfiTree Slideshow"
            className="slideshow-image"
          />
        </div>
      </div>
      <div className="hero-bottom-border"></div>
    </section>
  );
};

export default HeroSection;
