import React, { useEffect, useState, useRef } from "react";
import { useTransactions } from "./useTransactions";
import "../css/FrameStyles.css";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";
import SummaryCard from "./SummaryCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";

function CapitalGainTransactionsFrame({ username }) {
  const backendUrl = "https://profitree-backend-9482feeffc4a.herokuapp.com";
  const isMobileView = window.innerWidth <= 768;
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [transactionTypes] = useState([
    { name: "SELL", value: "SELL" },
    { name: "DIVIDEND", value: "DIVIDEND" },
    { name: "OPTION", value: "OPTION" },
  ]);
  const [taxTypes] = useState([
    { name: "Long Term(15%)", value: "Long Term(15%)" },
    { name: "Short Term(30%)", value: "Short Term(30%)" },
    { name: "Dividend(30%)", value: "Dividend(30%)" },
    { name: "OPTION(30%)", value: "OPTION(30%)" },
  ]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.IN },
    taxType: { value: null, matchMode: FilterMatchMode.IN },
  });
  const [yearFilter, setYearFilter] = useState(
    new Date().getFullYear().toString()
  );

  const [expandedRows, setExpandedRows] = useState(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const { data, loading, error } = useTransactions(
    "transactions/getCurrentYearTaxableProfit",
    username
  );

  const [totalTaxesOwed, setTotalTaxesOwed] = useState("");
  const [totalCapitalGain, setTotalCapitalGain] = useState("");
  const [longTermTaxRate, setLongTermTaxRate] = useState(20); // Default value
  const [shortTermTaxRate, setShortTermTaxRate] = useState(30); // Default value

  // Fetch tax rates from backend
  const getTaxRates = async () => {
        if (!username) return;
        try {
            console.log("Fetching tax rates for username:", username);
            const response = await axios.get(`${backendUrl}/profile/taxRates`, {
                   params: { username },
            });
             if (response.status === 200 && response.data) {
                 console.log("Tax Rates Fetched:", response.data);
                 setLongTermTaxRate(response.data.LONG_TERM);
                 setShortTermTaxRate(response.data.SHORT_TERM);
             } else {
                 alert("Couldn't fetch tax rates.");
            }
            } catch (err) {
            console.error("Error fetching tax rates:", err.response?.data || err);
            }
  };

  const getSummaryCardsValue = (data = tableData) => {
    // Calculate total taxes owed and total capital gain
    setTotalTaxesOwed(
      data.reduce((acc, transaction) => {
        const taxType = transaction.capitalGainTaxType;
        const taxRate = taxType === "SHORT" ? shortTermTaxRate /100 : longTermTaxRate /100 ;
        return acc + Math.abs(transaction.profitLossWithoutPrefix * taxRate);
      }, 0)
    );

    setTotalCapitalGain(
      data.reduce((acc, transaction) => {
        return acc + Math.abs(transaction.profitLossWithoutPrefix);
      }, 0)
    );
  };

    useEffect(() => {
      const fetchAndProcessData = async () => {
        if (username) {
            await getTaxRates(); // Ensure tax rates are fetched first
        }

        if (!loading && error === null) {
            let tempTableData = cloneDeep(data);

            // Apply Year Filter
            if (yearFilter) {
                tempTableData = data.filter((item) => item.date.split("-")[0] === yearFilter);
            }

            const processedData = tempTableData.map((item) => {
                const tempItem = cloneDeep(item);

                tempItem.purchaseDate =
                    item.settlementTransactions.length > 1
                        ? "Multiple"
                        : item.settlementTransactions.length === 1
                        ? item.settlementTransactions[0].date
                        : item.date;

                tempItem.purchasePrice = `${
                    item.settlementTransactions.length > 0
                        ? (
                              item.settlementTransactions.reduce(
                                  (acc, transaction) => acc + transaction.price * transaction.quantity,
                                  0
                              ) /
                              item.settlementTransactions.reduce(
                                  (acc, transaction) => acc + transaction.quantity,
                                  0
                              )
                          ).toFixed(2)
                        : "0.00"
                }`;

                tempItem.taxType =
                    item.capitalGainTaxType === "LONG"
                        ? `Long Term (${longTermTaxRate}%)`
                        : item.capitalGainTaxType === "SHORT"
                        ? `Short Term (${shortTermTaxRate}%)`
                        : `Short Term (${shortTermTaxRate}%)`;

                const taxRate = item.capitalGainTaxType === "LONG" ? longTermTaxRate : shortTermTaxRate;
                tempItem.taxesOwed = `$${Math.round(item.profitLoss * (taxRate / 100))}`;

                tempItem.price = `$${item.price}`;
                tempItem.profitLossWithoutPrefix = item.profitLoss;
                tempItem.profitLoss = `$${item.profitLoss}`;

                return tempItem;
            });

            setTableData(processedData);
            getSummaryCardsValue(processedData); // Update summary values
        }
    };

    fetchAndProcessData(); // Call the function
}, [username, data, yearFilter, longTermTaxRate, shortTermTaxRate]);



  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          animationData={loadingAnimation}
          style={{ height: "150px", width: "150px" }} // Adjust loader size
        />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        Error: {error}, please contact support for help:
        support@profitree-tax.com
      </div>
    );
  }

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.IN },
      taxType: { value: null, matchMode: FilterMatchMode.IN },
    });
    setGlobalFilterValue("");
    setYearFilter("");
    if (tableRef.current) {
      tableRef.current.reset();
    }
  };

  const renderHeader = () => {
    return (
      <div className="datatable-header-wrapper">
        <div className="datatable-filters-wrapper">
          <label>
            Year
            <select
              value={yearFilter}
              onChange={(e) => setYearFilter(e.target.value)}
            >
              <option value="All">All</option>
              {[
                ...new Set([
                  ...data.map((d) => d.date.split("-")[0]),
                  new Date().getFullYear().toString(), // Ensure the current year is always included
                ]),
              ].map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </label>
        </div>

        <IconField>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear Filters"
            outlined
            onClick={clearFilter}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 50 50"
          >
            <path d="M 21 3 C 11.622998 3 4 10.623005 4 20 C 4 29.376995 11.622998 37 21 37 C 24.712383 37 28.139151 35.791079 30.9375 33.765625 L 44.085938 46.914062 L 46.914062 44.085938 L 33.886719 31.058594 C 36.443536 28.083 38 24.223631 38 20 C 38 10.623005 30.377002 3 21 3 z M 21 5 C 29.296122 5 36 11.703883 36 20 C 36 28.296117 29.296122 35 21 35 C 12.703878 35 6 28.296117 6 20 C 6 11.703883 12.703878 5 21 5 z"></path>
          </svg>
          <InputText
            value={globalFilterValue || ""}
            onChange={onGlobalFilterChange}
            placeholder="Search by keyword"
          />
        </IconField>
      </div>
    );
  };

  const itemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.name}</span>
      </div>
    );
  };

  const transactionTypeRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={transactionTypes}
        itemTemplate={itemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const taxTypeRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={taxTypes}
        itemTemplate={itemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.settlementTransactions.length > 0 || isMobileView;
  };

  const rowExpansionTemplate = (data) => {
    return !isMobileView ? (
      <div className="p-3">
        <h5>Settlement info</h5>
        <DataTable value={data.settlementTransactions}>
          <Column field="instrument_id" header="Ticker" sortable></Column>
          <Column field="type" header="Transaction Type" sortable></Column>
          <Column field="costBasis" header="Purchase Price" sortable></Column>
          <Column
            field="totalSettledQuantity"
            header="Purchase Quantity"
            sortable
          ></Column>
          <Column
            field="settledQuantityForCurrentTransaction"
            header="Settled Quantity(Current Tx)"
            sortable
          ></Column>
          <Column field="date" header="Purchase Date" sortable></Column>
        </DataTable>
      </div>
    ) : (
      <div className="mobile-expand-info">
        <div className="expand-row">
          <h4>Account Name:</h4>
          <p>{data.accountName}</p>
        </div>
        <div className="expand-row">
          <h4>Ticker:</h4>
          <p>{data.tickerSymbol}</p>
        </div>
        <div className="expand-row">
          <h4>Transaction Type:</h4>
          <p>{data.type}</p>
        </div>

        <div className="expand-row">
          <h4>Purchase Date:</h4>
          <p>{data.purchaseDate}</p>
        </div>
        <div className="expand-row">
          <h4>Purchase Price:</h4>
          <p>{data.purchasePrice}</p>
        </div>
        <div className="expand-row">
          <h4>Sold Quantity:</h4>
          <p>{data.quantity}</p>
        </div>
        <div className="expand-row">
          <h4>Sold Date:</h4>
          <p>{data.date}</p>
        </div>
        <div className="expand-row">
          <h4>Sold Price:</h4>
          <p>{data.price}</p>
        </div>
        <div className="expand-row">
          <h4>Profit Amt:</h4>
          <p>{data.profitLoss}</p>
        </div>
        <div className="expand-row">
          <h4>Tax Type:</h4>
          <p>{data.taxType}</p>
        </div>
        <div className="expand-row">
          <h4>Taxes Owed:</h4>
          <p>{data.taxesOwed}</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="summary-container">
        <SummaryCard
          title="Total Capital Gains"
          value={totalCapitalGain}
          prefix="$"
        />
        <SummaryCard
          title="Total Taxes Owed"
          value={totalTaxesOwed}
          prefix="$"
        />
      </div>

      <DataTable
        value={tableData}
        removableSort
        sortMode="multiple"
        paginator
        rows={10}
        dataKey="id"
        filters={filters}
        loading={loading}
        ref={tableRef}
        multiSortMeta={[
          {
            field: "taxesOwed",
            order: "-1",
          },
        ]}
        responsiveLayout="stack" // Enables stacking on smaller screens
        breakpoint="960px" // Define at what width stacking happens
        globalFilterFields={[
          "accountName",
          "tickerSymbol",
          "type",
          "purchaseDate",
          "date",
          "taxType",
        ]}
        header={renderHeader()}
        emptyMessage="No transactions found."
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        onValueChange={(e) => {
          getSummaryCardsValue(e);
        }}
      >
        <Column
          expander={allowExpansion}
          style={{ width: "1rem" }}
          className={isMobileView ? "mobile-row-expander" : ""}
        />
        {!isMobileView ? (
          <Column field="accountName" header="Account Name" sortable></Column>
        ) : null}
        <Column field="tickerSymbol" header="Ticker" sortable></Column>
        {!isMobileView ? (
          <Column
            field={"type"}
            header="Transaction Type"
            sortable
            showFilterMenu={true}
            style={{ maxWidth: "10rem", minWidth: "10rem" }}
            filter
            filterField="type"
            showFilterMatchModes={false}
            filterElement={transactionTypeRowFilterTemplate}
            filterMenuStyle={{ width: "14rem" }}
          ></Column>
        ) : null}
        {!isMobileView ? (
          <Column field="purchaseDate" header="Purchase Date" sortable></Column>
        ) : null}
        {!isMobileView ? (
          <Column
            field="purchasePrice"
            header="Purchase Price"
            sortable
          ></Column>
        ) : null}
        {!isMobileView ? (
          <Column field="quantity" header="Sold Quantity" sortable></Column>
        ) : null}
        {!isMobileView ? (
          <Column field="date" header="Sold Date" sortable></Column>
        ) : null}
        {!isMobileView ? (
          <Column field="price" header="Sold Price" sortable></Column>
        ) : null}
        <Column field="profitLoss" header="Profit Amt" sortable></Column>
        {!isMobileView ? (
          <Column
            field="taxType"
            header="Tax Type"
            ortable
            showFilterMenu={true}
            style={{ maxWidth: "10rem", minWidth: "10rem" }}
            filter
            filterField="taxType"
            filterElement={taxTypeRowFilterTemplate}
            showFilterMatchModes={false}
          ></Column>
        ) : null}
        <Column field="taxesOwed" header="Taxes Owed" sortable></Column>
      </DataTable>
    </div>
  );
}

export default CapitalGainTransactionsFrame;
