import React from "react";

function Footer() {
  return (
    <footer>
      <p>©2025 ProfiTree. All rights reserved.</p>
      <nav>
        <a href="/about" style={{ color: "#f8f9fa", marginRight: "10px" }}>
          About
        </a>
        <a href="/terms" style={{ color: "#f8f9fa", marginRight: "10px" }}>
          Terms & Conditions
        </a>
        <a href="/privacy" style={{ color: "#f8f9fa" }}>
          Privacy Policy
        </a>
      </nav>
    </footer>
  );
}

export default Footer;
