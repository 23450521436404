import { useState, useEffect } from "react";
import axios from "axios";

const backendUrl = "https://profitree-backend-9482feeffc4a.herokuapp.com";

export const useTransactions = (endpoint, username) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${backendUrl}/${endpoint}`, null, {
          params: { username },
        });
        setData(response.data);
      } catch (err) {
        setError(err.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [endpoint]);

  return { data, loading, error };
};
