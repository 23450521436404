import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";

function SnapTradeTransactions({ username }) {
  const tableRef = useRef(null);
  const [originalData, setOriginalData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.IN },
  });

  const transactionTypes = [
    { name: "BUY", value: "BUY" },
    { name: "SELL", value: "SELL" },
    { name: "OPTION", value: "OPTION" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://profitree-backend-9482feeffc4a.herokuapp.com/debugTransactions/getSnapTradeActivities?username=${username}`,
          {
            method: "POST",
          }
        );

        const result = await response.json();

        const transformed = result.map((item) => ({
          id: item.id,
          institution: item.institution,
          accountName: item.account?.name || "",
          rawSymbol: item.symbol?.rawSymbol || "",
          settlementDate: item.settlementDate?.split("T")[0],
          type: item.optionType && item.optionType !== "" ? "OPTION" : item.type,
          optionType: (item.optionType + "_" + item.optionSymbol?.optionType) || "",
          strikePrice : item.optionSymbol?.strikePrice || "",
          expirationDate : item.optionSymbol?.expirationDate || "",
          units: item.units,
          price: item.price,
          amount: item.amount,
          description: item.description,
        }));

        setOriginalData(transformed);
        setTableData(transformed);
      } catch (err) {
        console.error("Failed to fetch SnapTrade data", err);
      }
    };

    fetchData();
  }, [username]);



  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters((prev) => ({
      ...prev,
      global: { ...prev.global, value },
    }));
    setGlobalFilterValue(value);
  };

  const clearFilter = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.IN },
    });
    setGlobalFilterValue("");
    if (tableRef.current) tableRef.current.reset();
  };

  const transactionTypeRowFilterTemplate = (options) => (
    <MultiSelect
      value={options.value}
      options={transactionTypes}
      onChange={(e) => options.filterApplyCallback(e.value)}
      optionLabel="name"
      placeholder="Any"
      className="p-column-filter"
      maxSelectedLabels={1}
    />
  );

  const renderHeader = () => (
    <div className="datatable-header-wrapper">
      <IconField>
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear Filters"
          outlined
          onClick={clearFilter}
        />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Search transactions"
        />
      </IconField>
    </div>
  );

  if (!originalData.length) {
    return (
      <div className="centered-loader">
        <Lottie animationData={loadingAnimation} style={{ height: 150 }} />
      </div>
    );
  }

  return (
    <div>
      <DataTable
        value={tableData}
        ref={tableRef}
        paginator
        rows={10}
        filters={filters}
        removableSort
        sortMode="multiple"
        globalFilterFields={[
          "institution",
          "rawSymbol",
          "settlementDate",
          "type",
          "optionType",
          "description",
        ]}
        header={renderHeader()}
        emptyMessage="No SnapTrade transactions found."
        responsiveLayout="stack"
        breakpoint="960px"
        dataKey="id"
      >
        <Column field="institution" header="Institution" sortable />
        <Column field="accountName" header="Account" sortable />
        <Column field="rawSymbol" header="Ticker" sortable />
        <Column
          field="type"
          header="Transaction Type"
          sortable
          filter
          filterField="type"
          showFilterMenu
          filterMenuStyle={{ width: "14rem" }}
          filterElement={transactionTypeRowFilterTemplate}
          showFilterMatchModes={false}
        />

        <Column field="settlementDate" header="Settlement Date" sortable />
        <Column field="units" header="Units" sortable />
        <Column field="price" header="Price" sortable />
        <Column field="amount" header="Amount" sortable />
        <Column field="optionType" header="Option Type" sortable />
        <Column field="strikePrice" header="Strike Price" sortable />
        <Column field="expirationDate" header="Expiration Date" sortable />
        <Column field="description" header="Description" sortable />
      </DataTable>
    </div>
  );
}

export default SnapTradeTransactions;
