import React from "react";
import Lottie from "lottie-react";
import animationData from "../assets/trend-arrow.json";
import "../css/StatsSection.css";

const StatsSection = () => {
  return (
    <section className="two-frame-stats">
      <div className="content-wrapper">
        <div className="white-underline-title">
          The Unclaimed <span className="highlight-red">Tax-Loss</span> Problem
        </div>
        <div className="stats-content-wrapper">
          {/* Left Frame */}
          <div className="left-frame">
            <div className="stats-info-card">
              <h3>19.4%</h3>
              <h4>Market Decline</h4>
              <p>
                The S&P 500 dropped 19.4% in 2022, one of the worst-performing
                years in recent history.
              </p>
              <p>
                This downturn created significant opportunities for tax-loss
                harvesting.
              </p>
            </div>
            <div className="stats-info-card">
              <h3>$1.6 Trillion</h3>
              <h4>Capital Gains Reported</h4>
              <p>
                Investors reported a staggering $1.6 trillion in capital gains
                during 2022, despite the market's downturn.
              </p>
              <p>
                This shows profits were focused, while tax strategies were
                overlooked.
              </p>
            </div>
            <div className="stats-info-card">
              <h3>$30 Billion</h3>
              <h4>Capital Losses Reported</h4>
              <p>
                Taxpayers reported only $30 billion in capital losses, leaving
                billions in unclaimed losses
              </p>
              <p>
                This gap reveals a massive missed opportunity to offset gains
                and lower taxes.
              </p>
            </div>
            <p className="stats-section-heading">
              The Challenges Investors Face
            </p>
            <div className="stats-info-card">
              <h3>Complex Wash Sale Rules</h3>
              <p>
                Wash sale rules disallow losses if you repurchase identical
                stocks within 30 days before or after selling at a loss.
              </p>
              <p>
                Tracking and avoiding these transactions manually is complex,
                leading to missed tax benefits.
              </p>
            </div>
            <div className="stats-info-card">
              <h3>Lack of Awareness</h3>
              <p>
                Many investors don't know how or when to harvest tax losses,
                leaving billions in potential savings unclaimed.
              </p>
              <p>This lack of awareness creates a gap in financial planning.</p>
            </div>
            <div className="stats-info-card">
              <h3>High Advisor Fees</h3>
              <p>
                Advisors and robo-advisors charge 0.25%-1% of portfolio value
                anually, making tax optimization costly.
              </p>
              <p>
                This pricing model excludes DIY investors from accessing smarter
                tax-savings strategies.
              </p>
            </div>
          </div>
          <Lottie
            animationData={animationData}
            style={{ height: "550px", width: "550px", fill: "#ddd" }} // Adjust loader size
          />
        </div>
      </div>
    </section>
  );
};

export default StatsSection;
