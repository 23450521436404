import React, { useState } from "react";
import PlaygroundDisallowedTransactionsFrame from "./PlaygroundDisallowedTransactionsFrame";
import PlaygroundCapitalGainTransactionsFrame from "./PlaygroundCapitalGainTransactionsFrame";
import PlaygroundCapitalLossTransactionsFrame from "./PlaygroundCapitalLossTransactionsFrame";
import PlaygroundTaxLossOpportunitiesFrame from "./PlaygroundTaxLossOpportunitiesFrame";
import PlaygroundWashSaleAvoidance from "./PlaygroundWashSaleAvoidance";
import "../css//Dashboard.css";

function Playground({ transactions }) {
    const [activeTab, setActiveTab] = useState("capitalGains");

    const tooltips = {
        capitalGains: "View your realized capital gains for the current year.",
        capitalLosses: "View your realized capital losses for the current year.",
        taxLossOpportunities: "Discover opportunities to harvest tax losses.",
        disallowedTransactions: "See transactions marked as wash sales in the past.",
        washSaleAvoidance: "See which tickers/stocks you should avoid trading to prevent triggering wash sales.",
    };

    return (
        <div className="dashboard-container">
            <div className="tabs">
                <div className="tooltip-container">
                    <button
                        onClick={() => setActiveTab("capitalGains")}
                        className={activeTab === "capitalGains" ? "active" : ""}>
                        Capital Gains
                    </button>
                    <span className="tooltip">{tooltips.capitalGains}</span>
                </div>
                <div className="tooltip-container">
                    <button
                        onClick={() => setActiveTab("capitalLosses")}
                        className={activeTab === "capitalLosses" ? "active" : ""}>
                        Capital Losses
                    </button>
                    <span className="tooltip">{tooltips.capitalLosses}</span>
                </div>
                <div className="tooltip-container">
                    <button
                        onClick={() => setActiveTab("taxLossOpportunities")}
                        className={activeTab === "taxLossOpportunities" ? "active" : ""}>
                        TLH Opportunities
                    </button>
                    <span className="tooltip">{tooltips.taxLossOpportunities}</span>
                </div>
                <div className="tooltip-container">
                    <button
                        onClick={() => setActiveTab("disallowedTransactions")}
                        className={activeTab === "disallowedTransactions" ? "active" : ""}>
                        Past Wash Sale Transactions
                    </button>
                    <span className="tooltip">{tooltips.disallowedTransactions}</span>
                </div>
                <div className="tooltip-container">
                    <button
                        onClick={() => setActiveTab("washSaleAvoidance")}
                        className={activeTab === "washSaleAvoidance" ? "active" : ""}>
                        Wash Sale Avoidance
                    </button>
                    <span className="tooltip">{tooltips.washSaleAvoidance}</span>
                </div>
            </div>
            <div className="tab-content">
                {activeTab === "disallowedTransactions" && (
                    <PlaygroundDisallowedTransactionsFrame transactions={transactions} />
                )}
                {activeTab === "washSaleAvoidance" && (
                    <PlaygroundWashSaleAvoidance transactions={transactions} />
                )}
                {activeTab === "capitalGains" && (
                    <PlaygroundCapitalGainTransactionsFrame transactions={transactions} />
                )}
                {activeTab === "capitalLosses" && (
                    <PlaygroundCapitalLossTransactionsFrame transactions={transactions} />
                )}
                {activeTab === "taxLossOpportunities" && (
                    <PlaygroundTaxLossOpportunitiesFrame transactions={transactions} />
                )}
            </div>
        </div>
    );
}

export default Playground;
