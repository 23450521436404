import React, { useEffect, useState } from "react";
import { FaBars, FaTimes, FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../css/Header.css";
import logo from "../assets/Presentation1.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const backendUrl = "https://profitree-backend-9482feeffc4a.herokuapp.com"; // Your backend URL

function Header({ username }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem("username"); // Clear persistence
    navigate("/");
    window.location.reload();
  };

  const toggleAccountStatus = async (accountId, currentStatus) => {
    try {
      const response = await axios.post(`${backendUrl}/accounts/flip`, null, {
        params: { username, accountId },
      });
      if (response.status === 200) {
        setAccounts((prevAccounts) =>
          prevAccounts.map((account) =>
            account.accountId === accountId
              ? {
                  ...account,
                  enabled: !currentStatus,
                }
              : account
          )
        );
        // Reload the page after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setError("Failed to update account status.");
      }
    } catch (err) {
      console.error("Error toggling account status:", err);
      setError("Failed to update account status.");
    }
  };

  useEffect(() => {
    if (username) {
      const fetchAccounts = async () => {
        try {
          const response = await axios.post(
            `${backendUrl}/accounts/getList`,
            null,
            {
              params: { username },
            }
          );
          if (response.status === 200 && response.data) {
            setAccounts(response.data);
          } else {
            setError("Failed to fetch accounts.");
          }
        } catch (err) {
          console.error("Error fetching accounts:", err);
          setError("Failed to load accounts.");
        }
      };
      fetchAccounts();
    }
  }, [username]);

  return (
    <header className="header">
      <div className="content-wrapper">
        {/* Logo */}
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>

        {/* Hamburger Menu */}
        <button className="menu-toggle" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Dropdown Menu for Mobile */}
        <nav className={`nav-links ${menuOpen ? "active" : ""}`}>
          {/* <Link to="/account" onClick={toggleMenu}>
            Account
          </Link> */}
          <Link to="/dashboard" onClick={toggleMenu}>
            Dashboard
          </Link>
          <Link to="/about" onClick={toggleMenu}>
            About Us
          </Link>
          <Link to="/pricing" onClick={toggleMenu}>
            Pricing
          </Link>
          <Link to="/blog" onClick={toggleMenu}>
            Learning
          </Link>
          <Link to="/learning" onClick={toggleMenu}>
            FAQ
          </Link>
          <div className="header-buttons">
            {username ? ( // Directly check for username prop
              <div className="user-account" onClick={toggleDropdown}>
                <FaUserCircle className="user-icon" />
                <span className="username">{username}</span>
                {showDropdown && (
                  <div className="dropdown-menu">
                    <a href="/profile">Profile</a>
                    <a href="/reset-password">Reset Password</a>
                    <a href="/contact-us">Contact Us</a>
                    <button className="btn btn-demo" onClick={handleLogout}>
                      Logout
                    </button>
                    <div className="accounts-seperator"></div>
                    <div className="active-accounts-profile">
                      <div className="accounts-info-header">
                        <p>Account Name</p>
                        <p>Action</p>
                      </div>
                      {accounts.map((account) => (
                        <div className="accounts-info">
                          <p>{account.accountName}</p>
                          {account.enabled ? (
                            <button
                              className="status-disable"
                              onClick={() =>
                                toggleAccountStatus(account.accountId, false)
                              }
                            >
                              Disable
                            </button>
                          ) : (
                            <button
                              className="action-button-enable"
                              onClick={() =>
                                toggleAccountStatus(account.accountId, true)
                              }
                            >
                              Enable
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <Link to="/auth">
                  <button className="btn btn-demo cta-secondary-button" onClick={toggleMenu}>
                    Log In
                  </button>
                </Link>
              </>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
