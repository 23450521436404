import React, { useEffect, useState, useRef } from "react";
import { useTransactions } from "./useTransactions";
import "../css/FrameStyles.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";

function SettledTransactionsFrame({ username }) {
  const tableRef = useRef(null);
  const [originalData, setOriginalData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [yearFilter, setYearFilter] = useState("All");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    type: { value: null, matchMode: FilterMatchMode.IN },
  });

  const transactionTypes = [
    { name: "SELL", value: "SELL" },
    { name: "DIVIDEND", value: "DIVIDEND" },
    { name: "OPTION", value: "OPTION" },
    { name : "BUY" , value: "BUY" },
  ];

  const { data, loading, error } = useTransactions(
    "debugTransactions/getSettledTransactions",
    username
  );

  // Filter transactions by year
  const filterByYear = (transactions, year) => {
    if (!year || year === "All") return transactions;
    return transactions.filter((tx) => tx.date.startsWith(year));
  };

  useEffect(() => {
    if (data && !loading && !error) {
      console.log("API response data:", data);
      setOriginalData(data);
    }
  }, [data, loading, error]);

  // Apply year filtering when yearFilter or originalData changes
  useEffect(() => {
    const filtered = filterByYear(originalData, yearFilter);
    setTableData(filtered);
  }, [originalData, yearFilter]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters((prev) => ({
      ...prev,
      global: { ...prev.global, value },
    }));
    setGlobalFilterValue(value);
  };

  const clearFilter = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.IN },
    });
    setGlobalFilterValue("");
    setYearFilter("All");
    if (tableRef.current) tableRef.current.reset();
  };

  const transactionTypeRowFilterTemplate = (options) => (
    <MultiSelect
      value={options.value}
      options={transactionTypes}
      onChange={(e) => options.filterApplyCallback(e.value)}
      optionLabel="name"
      placeholder="Any"
      className="p-column-filter"
      maxSelectedLabels={1}
    />
  );

  const renderHeader = () => {
    const uniqueYears = [
      ...new Set(originalData.map((d) => d.date.split("-")[0])),
    ].sort((a, b) => b - a);

    return (
      <div className="datatable-header-wrapper">
        <div className="datatable-filters-wrapper">
          <label>
            Year:{" "}
            <select
              value={yearFilter}
              onChange={(e) => setYearFilter(e.target.value)}
              style={{ marginLeft: "0.5rem" }}
            >
              <option value="All">All</option>
              {uniqueYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </label>
        </div>

        <IconField>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear Filters"
            outlined
            onClick={clearFilter}
          />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search transactions"
          />
        </IconField>
      </div>
    );
  };

  const allowExpansion = (rowData) =>
    rowData.settlementTransactions && rowData.settlementTransactions.length > 0;

  const rowExpansionTemplate = (rowData) => (
    <div className="p-3">
      <h5>Settlement Transactions</h5>
      <DataTable value={rowData.settlementTransactions} responsiveLayout="scroll">
        <Column field="company_name" header="Company" />
        <Column field="instrument_id" header="Ticker" />
        <Column field="type" header="Type" />
        <Column field="price" header="Purchase Price" />
        <Column field="quantity" header="Quantity" />
        <Column field="costBasis" header="Cost Basis" />
        <Column field="date" header="Date" />
        <Column field="settledQuantityForCurrentTransaction" header="Settled Qty (This Txn)" />
        <Column field="totalSettledQuantity" header="Total Settled Qty" />
      </DataTable>
    </div>
  );

  if (loading) {
    return (
      <div className="centered-loader">
        <Lottie animationData={loadingAnimation} style={{ height: 150 }} />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        Error: {error}. Please contact <strong>support@profitree-tax.com</strong>
      </div>
    );
  }

  return (
    <div>
      <DataTable
        value={tableData}
        ref={tableRef}
        paginator
        rows={10}
        filters={filters}
        loading={loading}
        removableSort
        sortMode="multiple"
        globalFilterFields={[
          "accountName",
          "tickerSymbol",
          "type",
          "date",
          "settlementDate",
        ]}
        header={renderHeader()}
        emptyMessage="No transactions found."
        responsiveLayout="stack"
        breakpoint="960px"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="id"
      >
        <Column expander={allowExpansion} style={{ width: "1rem" }} />
        <Column field="accountName" header="Account Name" sortable />
        <Column field="tickerSymbol" header="Ticker" sortable />
        <Column
          field="type"
          header="Transaction Type"
          sortable
          filter
          filterField="type"
          showFilterMenu
          filterMenuStyle={{ width: "14rem" }}
          filterElement={transactionTypeRowFilterTemplate}
          showFilterMatchModes={false}
        />
        <Column field="date" header="Trade Date" sortable />
        <Column field="price" header="Price" sortable />
        <Column field="quantity" header="Quantity" sortable />
        <Column field="settledQuantity" header="Settled Quantity" sortable />
        <Column field="totalAmount" header="Total Amount" sortable />
        <Column field="capitalGainTaxType" header="Cap. Gain Type" sortable />
        <Column field="profitLoss" header="Profit/Loss" sortable />
      </DataTable>
    </div>
  );
}

export default SettledTransactionsFrame;
