import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AuthPage from "./components/AuthPage";
import KnowledgeBase from "./components/KnowledgeBase";
import Dashboard from "./components/Dashboard";
import DebugDashboard from "./components/DebugDashboard";
import ResetPassword from "./components/ResetPassword";
import SignUp from "./components/SignUp";
import Account from "./components/Accounts";
import ContactUs from "./components/ContactUs";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicies from "./components/PrivacyPolicies";
import About from "./components/About";
import BlogGrid from "./components/BlogGrid";
import BlogDetail from "./components/BlogDetail";
import Pricing from "./components/Pricing";
import Profile from "./components/Profile";
import PricingTable from "./components/PricingTable";
import TaxSavingsCalculator from "./components/TaxSavingsCalculator";
import DemoComponent from "./components/DemoComponent";
import ComparisonTable from "./components/ComparisonTable";
import { useNavigate } from "react-router-dom";
import { HomePage } from "./pages/Home";
import "./App.css";
import Modal from "./components/Modal";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const App = () => {
  const location = useLocation(); // Now it works because App is inside Router
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState({
    open: false,
    type: "account",
  });

  useEffect(() => {
    if (typeof window.gtag === "function") {
      window.gtag("config", "G-VHQR7N5W71", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  const [username, setUsername] = useState(
    () => localStorage.getItem("username") || null
  );

  useEffect(() => {
    if (username) {
      localStorage.setItem("username", username);
    } else {
      localStorage.removeItem("username");
    }
  }, [username]);

  const handleLogin = (user) => {
    setUsername(user);
  };

  const handleSignup = (user) => {
    setUsername(user);
  };

  const handleLogout = () => {
    localStorage.removeItem("username");
    setUsername(null);
    navigate("/");
  };

  return (
    <div className="AppContainer">
      <Header username={username} onLogout={handleLogout} />
      <main className="Content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/auth" element={<AuthPage onLogin={handleLogin} />} />
          <Route path="/learning" element={<KnowledgeBase />} />
          <Route path="/blog" element={<BlogGrid />} />
          <Route
            path="/dashboard"
            element={
              username ? (
                <Dashboard setModalOpen={setModalOpen} username={username} />
              ) : (
                <AuthPage onLogin={handleLogin} />
              )
            }
          />
          <Route
                      path="/debugDashboard"
                      element={
                        username ? (
                          <DebugDashboard setModalOpen={setModalOpen} username={username} />
                        ) : (
                          <AuthPage onLogin={handleLogin} />
                        )
                      }
                    />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signup" element={<SignUp onSignup={handleSignup} />} />
          <Route
            path="/account"
            element={
              username ? (
                <Account username={username} />
              ) : (
                <AuthPage onLogin={handleLogin} />
              )
            }
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicies />} />
          <Route path="/about" element={<About />} />

          <Route exact path="/" component={BlogGrid} />
          <Route path="/blog/:blogId" element={<BlogDetail />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/playground" element={<DemoComponent />}></Route>
          <Route path="/tax-savings-calculator" element={<TaxSavingsCalculator />}></Route>
          <Route path="/comparison-table" element={<ComparisonTable />}> </Route>
          <Route path="/profile" element={<Profile username={username} setModalOpen={setModalOpen}/>}></Route>
        </Routes>
      </main>
      <Footer />
      <Modal
        username={username}
        isOpen={isModalOpen}
        onClose={() => setModalOpen({ open: false, type: "account" })}
      />
    </div>
  );
};

export default App;
