import React from "react";
import "../css/TermsAndConditions.css";

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <h1>Terms and Conditions for ProfiTree</h1>

            <p><strong>Effective Date:</strong> 11th November 2024</p>

            <p>
                Welcome to ProfiTree! By using our website, application, or services
                (collectively referred to as "ProfiTree"), you agree to the following
                terms and conditions. Please read them carefully.
            </p>

            <hr />

            <h2>1. Acceptance of Terms</h2>
            <p>
                By accessing or using ProfiTree, you acknowledge that you have read,
                understood, and agree to be bound by these terms and conditions, as well
                as our Privacy Policy.
            </p>
            <p>If you do not agree to these terms, you may not use ProfiTree.</p>

            <hr />

            <h2>2. Services Provided</h2>
            <p>
                ProfiTree provides tools and features to assist users with tax-loss
                harvesting strategies, portfolio optimization, and related financial
                planning. ProfiTree does not offer legal, tax, or investment advice.
            </p>
            <ul>
                <li>
                    <strong>No Professional Advice:</strong> All information and tools
                    provided by ProfiTree are for informational purposes only. Please
                    consult a qualified financial advisor, tax professional, or legal
                    expert for advice tailored to your specific situation.
                </li>
                <li>
                    <strong>Accuracy:</strong> While we strive for accuracy, we do not
                    guarantee that all information on ProfiTree is complete, accurate, or
                    up to date.
                </li>
            </ul>

            <hr />

            <h2>3. User Responsibilities</h2>
            <p>You are responsible for:</p>
            <ul>
                <li>Providing accurate and truthful information when using ProfiTree.</li>
                <li>
                    Ensuring that your use of ProfiTree complies with all applicable laws
                    and regulations.
                </li>
                <li>
                    Maintaining the confidentiality of your account credentials and
                    restricting access to your account.
                </li>
            </ul>

            <hr />

            <h2>4. Account Registration</h2>
            <p>To use certain features of ProfiTree, you may need to create an account. You agree to:</p>
            <ul>
                <li>Provide accurate and complete registration information.</li>
                <li>Update your account information promptly if there are changes.</li>
                <li>Be responsible for all activities that occur under your account.</li>
            </ul>

            <hr />

            <h2>5. Fees and Subscriptions</h2>
            <p>ProfiTree may offer free and paid subscription plans. By subscribing to our paid services, you agree to:</p>
            <ul>
                <li>Pay all applicable fees as outlined in the subscription plan.</li>
                <li>Allow us to process payments through the method you provide.</li>
                <li>
                    Understand that subscription fees are non-refundable unless explicitly
                    stated otherwise.
                </li>
            </ul>

            <hr />

            <h2>6. Prohibited Activities</h2>
            <p>You agree not to:</p>
            <ul>
                <li>Use ProfiTree for any unlawful or fraudulent purposes.</li>
                <li>Attempt to reverse-engineer, modify, or hack our software or services.</li>
                <li>Use automated tools to scrape or extract data from ProfiTree.</li>
            </ul>

            <hr />

            <h2>7. Termination</h2>
            <p>
                We reserve the right to suspend or terminate your access to ProfiTree at
                our sole discretion, with or without notice, for any reason, including
                but not limited to:
            </p>
            <ul>
                <li>Violations of these terms.</li>
                <li>Unauthorized or illegal use of the platform.</li>
            </ul>

            <hr />

            <h2>8. Limitation of Liability</h2>
            <p>
                To the fullest extent permitted by law, ProfiTree and its affiliates,
                employees, and partners are not liable for:
            </p>
            <ul>
                <li>Any indirect, incidental, or consequential damages arising from your use of ProfiTree.</li>
                <li>Any errors, inaccuracies, or delays in the information provided by ProfiTree.</li>
                <li>
                    Losses resulting from investment decisions made based on information or
                    tools provided by ProfiTree.
                </li>
            </ul>

            <hr />

            <h2>9. Indemnification</h2>
            <p>
                You agree to indemnify and hold harmless ProfiTree, its affiliates,
                employees, and partners from any claims, losses, liabilities, or
                expenses arising from:
            </p>
            <ul>
                <li>Your use of ProfiTree.</li>
                <li>Your violation of these terms.</li>
            </ul>

            <hr />

            <h2>10. Intellectual Property</h2>
            <p>
                All content, trademarks, and software on ProfiTree are the property of
                ProfiTree or its licensors. You agree not to reproduce, distribute, or
                create derivative works from any content without our explicit permission.
            </p>

            <hr />

            <h2>11. Third-Party Services</h2>
            <p>
                ProfiTree may integrate with third-party services (e.g., financial account
                connections). Your use of these services is subject to their terms and
                conditions. ProfiTree is not responsible for any issues arising from
                third-party services.
            </p>

            <hr />

            <h2>12. Changes to Terms</h2>
            <p>
                ProfiTree reserves the right to modify these terms at any time. Changes
                will be effective upon posting on our website or notifying users through
                email or the platform. Continued use of ProfiTree constitutes acceptance
                of the updated terms.
            </p>

            <hr />

            <h2>13. Governing Law</h2>
            <p>
                These terms are governed by and construed in accordance with the laws of
                [Insert Jurisdiction]. Any disputes arising under these terms will be
                resolved exclusively in the courts of [Insert Jurisdiction].
            </p>

            <hr />

            <h2>14. Contact Information</h2>
            <p>
                If you have any questions about these terms, please contact us at:
            </p>
            <address>
                <strong>ProfiTree Support</strong>
                <br />
                Email: support@profitree-tax.com
            </address>

            <p>
                By using ProfiTree, you acknowledge that you have read and agree to these
                terms and conditions.
            </p>
        </div>
    );
};

export default TermsAndConditions;
